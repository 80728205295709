$bg-color: #F5F5F5;
$light-gray: #eaeaea;
$dark-gray: #525252;
$hover-gray: #7b7b7b;
$primary-green: #005661;
$highlight-green: #e1f0ee;
$hover-green: #c1c940;


$body-bg: $bg-color;
$body-color: $dark-gray;
$primary: $primary-green;
$secondary: $hover-green;

.bg-white {
    background-color: #F5F5F5;
}

.bg-light-gray {
    background-color: $light-gray;
}

.bg-light-green {
    background: linear-gradient(180deg,rgba(29,125,81,.1) 0,rgba(13,87,53,.1) 100%);
}

.bg-green {
    background-color: $primary-green;
}

.highlight {
    background: $highlight-green;
    background-repeat: no-repeat;
    background-size: 100%;
}


$nav-link-font-size: 1.25rem;
$nav-link-font-weight: 500;
$nav-link-color: $primary-green;
$nav-link-hover-color: $dark-gray;
