.image-row {
    overflow: hidden;
    .image-col {
        background-size: cover;
        background-position: center center;
        height: 400px;
    }
}


.nav {
    .nav-item {
        .nav-link {
            font-family: $headings-font-family;
        }
    }
}
