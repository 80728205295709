/*
Weitere Spacer-Größen
 */
 $spacer: 1rem;
 $spacers: (
         0: 0,
         1: $spacer * .25,
         2: $spacer * .5,
         3: $spacer,
         4: $spacer * 1.5,
         5: $spacer * 3,
         6: ($spacer * 4),      //64px
         7: ($spacer * 5),      //80px
         8: ($spacer * 6.25),   //100px
         9: ($spacer * 7.5),    //120px
         10: ($spacer * 9.375),  //150px
         11: ($spacer * 11.25),  //180px
         12: ($spacer * 13.25)  //180px
 );

 html {
        overflow-x: hidden;
 }

 /* Modals */
$modal-inner-padding: 0 2rem 2rem 2rem;
$modal-header-padding-y: 2rem;
$modal-header-padding-x: 2rem;
$modal-content-border-width: 0;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-border-radius: 0;