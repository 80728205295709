/* Button-Styles */

.header-teaser {
  background-color: $primary-green;
  background-image: url('../img/uwg-bg-meddersheim.jpg');
  background-size: cover;
  background-position: center bottom;
  color: #ffffff;
  position: relative;
}

.teaser-box {
  background-color: $primary-green;
  color: #ffffff;
  padding: 2.25rem 1.5rem 0.5rem 1.5rem;
  ul {
    li {
      padding-bottom: .5rem;
    }
  }
}

.image-fullsize {
  background-size: cover;
  background-position: center center;
  min-height: 400px;
}
