/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inter-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v12-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/inter-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v12-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v12-latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/inter-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/inter-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/inter-v12-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/inter-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/inter-v12-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}


$font-family-sans-serif: 'Inter', sans-serif;
$h2-font-size: 4.9rem;
$headings-margin-bottom: 1.7rem;
$headings-font-family: 'Inter', sans-serif;
$headings-font-style: normal;
$headings-font-weight: 700;
$headings-color: $primary;

$nav-link-padding-x: 0;

.section-subtitle {
  font-weight: 700;
  color: $primary;
  margin-bottom: .75rem;
  font-size: 1.75rem;
}

.section-list {
  font-weight: 700;
  color: $primary;
  margin-bottom: 1.75rem;
  font-size: 1.35rem;
}

a.link-green {
  text-decoration: none;
  color: $secondary;
}